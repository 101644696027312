import { TableCell, TableHead, TableRow, TableBody, Table } from "@material-ui/core";
import clsx from "clsx";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { formatDurationToString } from "helpers/formatDurationToString";
import { breakLines } from "helpers/stringHelpers";
import { useStore } from "store/StoreConfigs";
import { batchDistributionDiagramPageStyles } from "./BatchDistributionDiagramPageStyles";
import { LynxRedirectLink } from "components/LynxComponents/LynxRedirectLink/LynxRedirectLink";
import { routes } from "routes";

interface Props {
    expanded?: boolean;
}

export const BatchDistributionDiagramRsbAdjustmentsTable = ({ expanded }: Props) => {
    const { batchDistributionDiagramStore: store } = useStore();
    const classes = batchDistributionDiagramPageStyles();

    const tableHeaderCell = (title?: string, className?: string) => {
        return (
            <TableCell className={clsx(classes.overlayTableHeaderCell, className)}>
                <LynxTypography variant="body-xs" color="neutral400">
                    {breakLines(title)}
                </LynxTypography>
            </TableCell>
        );
    };

    return (
        <div className={clsx(classes.overlayTable, expanded && classes.rsbAdjustmentsTableExpanded)}>
            <div className={classes.overlayTableHeader}>
                <LynxTypography variant="body-sbold-s">RSB Adjustments ({store.rsbAdjustments.length})</LynxTypography>
            </div>
            <div className={clsx(!expanded && classes.rsbAdjustmentsTableHidden)}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {tableHeaderCell("Event ID")}
                            {tableHeaderCell("Temperature Range")}
                            {tableHeaderCell("Adjustment")}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {store.rsbAdjustments.map((x) => (
                            <TableRow key={x.eventId} className={classes.rsbAdjustmentsTableRow}>
                                <TableCell className={clsx(classes.overlayTableBodyCell, classes.eventIdTableBodyCell)}>
                                    <LynxRedirectLink
                                        variant="body-sbold-s"
                                        target="_blank"
                                        to={routes.eventDetails.to(x.eventId)}
                                    >
                                        {x.eventDisplayId}
                                    </LynxRedirectLink>
                                </TableCell>
                                <TableCell className={classes.overlayTableBodyCell}>
                                    <ul className={classes.rsbAdjustmentsList}>
                                        {x.adjustments.map((y) => (
                                            <li key={y.range}>
                                                <LynxTypography component="span">{y.range}</LynxTypography>
                                            </li>
                                        ))}
                                    </ul>
                                </TableCell>
                                <TableCell className={classes.overlayTableBodyCell}>
                                    <ul className={classes.rsbAdjustmentsList}>
                                        {x.adjustments.map((y) => (
                                            <li key={y.range}>
                                                <LynxTypography component="span">
                                                    {y.rsbAdjustment > 0 ? "+" : ""}
                                                    {formatDurationToString(y.rsbAdjustment)}
                                                </LynxTypography>
                                            </li>
                                        ))}
                                    </ul>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
};
