import { observer } from "mobx-react";
import { useStore } from "store/StoreConfigs";
import { batchDistributionDiagramPageStyles } from "./BatchDistributionDiagramPageStyles";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { breakLines } from "helpers/stringHelpers";
import clsx from "clsx";
import { LynxIcon } from "icons/LynxIcon";
import { formatTemperatureRange } from "helpers/formatTemperatureRange";
import { formatDurationToString } from "helpers/formatDurationToString";

interface Props {
    contentType: "baseline" | "all-events" | "relevant-events";
}

export const BatchDistributionDiagramSummaryTable = observer(({ contentType }: Props) => {
    const { batchDistributionDiagramStore: store } = useStore();
    const classes = batchDistributionDiagramPageStyles();

    const getTitle = () => {
        switch (contentType) {
            case "baseline":
                return `Flow: ${store.diagramData.flowName}`;
            case "all-events":
                return `All Events (${store.allEvents.length})`;
            case "relevant-events":
                return `Relevant Events (${store.relevantEvents.length})`;
        }
    };

    const excursionExists = store.diagramData.impacts.some((x) => x.excursionDuration > 0);

    const tableHeaderCell = (title?: string, className?: string) => {
        return (
            <TableCell className={clsx(classes.overlayTableHeaderCell, className)}>
                <LynxTypography variant="body-xs" color="neutral400">
                    {breakLines(title)}
                </LynxTypography>
            </TableCell>
        );
    };

    const isExpandedTable = contentType !== "baseline";

    return (
        <div className={classes.overlayTable}>
            <div className={classes.overlayTableHeader}>
                <LynxTypography variant="body-sbold-s">{getTitle()}</LynxTypography>
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        {isExpandedTable && excursionExists && tableHeaderCell("", classes.warningIconHeaderCell)}
                        {tableHeaderCell("Temperature\nRange")}
                        {tableHeaderCell("Product Stability\nBudget")}
                        {isExpandedTable && tableHeaderCell("Total Excursion\nHours")}
                        {isExpandedTable && tableHeaderCell("Product Remaining\nStability Budget")}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {store.diagramData.impacts
                        .slice()
                        .sort((a, b) => a.position - b.position)
                        .map((x) => (
                            <TableRow key={x.id}>
                                {isExpandedTable && excursionExists && (
                                    <TableCell
                                        className={clsx(classes.overlayTableBodyCell, classes.warningIconBodyCell)}
                                    >
                                        {x.excursionDuration > 0 && <LynxIcon name="triangleWarning" />}
                                    </TableCell>
                                )}
                                <TableCell className={classes.overlayTableBodyCell}>
                                    <LynxTypography>
                                        {formatTemperatureRange(
                                            x.rangeDisplayLowerLimit,
                                            x.rangeDisplayUpperLimit,
                                            x.rangeDisplayLowerLimitOperator,
                                            x.rangeDisplayUpperLimitOperator
                                        )}
                                    </LynxTypography>
                                </TableCell>
                                <TableCell className={classes.overlayTableBodyCell}>
                                    <LynxTypography>
                                        {formatDurationToString(x.baseline, x.baseline !== null)}
                                    </LynxTypography>
                                </TableCell>
                                {isExpandedTable && (
                                    <TableCell className={classes.overlayTableBodyCell}>
                                        <LynxTypography>
                                            {formatDurationToString(
                                                contentType === "all-events"
                                                    ? x.totalExcursionDurationNoBatchTracking
                                                    : x.totalExcursionDuration
                                            )}
                                        </LynxTypography>
                                    </TableCell>
                                )}
                                {isExpandedTable && (
                                    <TableCell className={classes.overlayTableBodyCell}>
                                        <LynxTypography
                                            variant={contentType === "relevant-events" ? "body-sbold" : "body"}
                                        >
                                            {formatDurationToString(
                                                contentType === "all-events"
                                                    ? x.productRsbNoBatchTracking
                                                    : x.productRsb
                                            )}
                                        </LynxTypography>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </div>
    );
});
