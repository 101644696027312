import "@xyflow/react/dist/style.css";
import { observer } from "mobx-react";
import { Controls, ReactFlow, useEdgesState, useNodesInitialized, useNodesState, useReactFlow } from "@xyflow/react";
import { useEffect, useState } from "react";
import { LocationNode } from "./LocationNode";
import { UnknownLocationNode } from "./UnknownLocationNode";
import { DeliveryNode } from "./DeliveryNode";
import { DeliveryEdge } from "./DeliveryEdge";
import { batchDistributionDiagramPageStyles } from "./BatchDistributionDiagramPageStyles";
import { getDeliveryEdgeStyles, getInitialNodesForDiagram, layoutNodes } from "./batchDistributionDiagramHelpers";
import { useStore } from "store/StoreConfigs";
import { LynxEdge, LynxEdgeType, LynxNode, LynxNodeType } from "./BatchDistributionDiagramTypes";
import { CenteredLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/CenteredLoadingIndicator";
import { GroupedDeliveriesNode } from "./GroupedDeliveriesNode";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { LynxIcon } from "icons/LynxIcon";
import { BatchDistributionDiagramSummaryTable } from "./BatchDistributionDiagramSummaryTable";
import clsx from "clsx";
import { BatchDistributionDiagramRsbAdjustmentsTable } from "./BatchDistributionDiagramRsbAdjustmentsTable";

const nodeTypes: { [key in LynxNodeType]: any } = {
    deliveryNode: DeliveryNode,
    locationNode: LocationNode,
    unknownLocationNode: UnknownLocationNode,
    groupedDeliveriesNode: GroupedDeliveriesNode,
};

const edgeTypes: { [key in LynxEdgeType]: any } = {
    deliveryEdge: DeliveryEdge,
};

export const BatchDistributionDiagram = observer(() => {
    const classes = batchDistributionDiagramPageStyles();
    const { batchDistributionDiagramStore } = useStore();
    const initialNodes = getInitialNodesForDiagram(
        batchDistributionDiagramStore.diagramData,
        batchDistributionDiagramStore.areRelevantObjectsHighlighted
    );

    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState<LynxEdge>([]);

    const [layoutComputed, setLayoutComputed] = useState(false);
    const [showLoading, setShowLoading] = useState(true);
    const nodesInitialized = useNodesInitialized();
    const { fitView } = useReactFlow();

    const [summaryTablesExpanded, setSummaryTablesExpanded] = useState(false);
    const [rsbAdjustmentsTableExpanded, setRsbAdjustmentsTableExpanded] = useState(false);

    useEffect(() => {
        const updatedNodes = nodes.map((node) => ({
            ...node,
            data: {
                ...node.data,
                isHighlighted: batchDistributionDiagramStore.areRelevantObjectsHighlighted,
            },
        }));

        const updatedEdges = edges.map((edge) => {
            return {
                ...edge,
                data: {
                    ...edge.data,
                    isHighlighted: batchDistributionDiagramStore.areRelevantObjectsHighlighted,
                },
                ...getDeliveryEdgeStyles(
                    batchDistributionDiagramStore.areRelevantObjectsHighlighted,
                    !!edge.data?.isOnRelevantPath
                ),
            };
        });

        setNodes(updatedNodes as LynxNode[]);
        setEdges(updatedEdges as LynxEdge[]);
    }, [batchDistributionDiagramStore.areRelevantObjectsHighlighted]);

    useEffect(() => {
        (async () => {
            if (nodesInitialized && !layoutComputed) {
                const result = await layoutNodes(nodes);
                setNodes(result.nodesAfterLayout);
                setEdges(result.edgesAfterLayout);
                setLayoutComputed(true);
            }
        })();
    }, [setNodes, setEdges, fitView, nodesInitialized, layoutComputed]);

    useEffect(() => {
        if (nodesInitialized && layoutComputed) {
            fitView();
            setShowLoading(false);
        }
    }, [nodesInitialized, layoutComputed]);

    return (
        <div className={classes.diagramContainer}>
            <ReactFlow
                maxZoom={10}
                minZoom={0.1}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                proOptions={{ hideAttribution: true }}
            >
                <Controls
                    showFitView={false}
                    showInteractive={false}
                    showZoom={false}
                    position="top-right"
                    className={classes.topDiagramControls}
                >
                    <div className={classes.flex}>
                        <LynxButton
                            variant="secondary"
                            onClick={() => setSummaryTablesExpanded((prev) => !prev)}
                            className={classes.overlayTableExpandButton}
                        >
                            <LynxIcon name={summaryTablesExpanded ? "angleDoubleSmallRight" : "angleDoubleSmallLeft"} />
                        </LynxButton>
                        <div className={classes.flex}>
                            {summaryTablesExpanded ? (
                                <>
                                    <BatchDistributionDiagramSummaryTable contentType="all-events" />
                                    <BatchDistributionDiagramSummaryTable contentType="relevant-events" />
                                </>
                            ) : (
                                <BatchDistributionDiagramSummaryTable contentType="baseline" />
                            )}
                        </div>
                    </div>
                    {batchDistributionDiagramStore.rsbAdjustments.length > 0 && (
                        <div className={classes.flex}>
                            <LynxButton
                                variant="secondary"
                                onClick={() => setRsbAdjustmentsTableExpanded((prev) => !prev)}
                                className={clsx(
                                    classes.overlayTableExpandButton,
                                    classes.rsbAdjustmentsTableExpandButton
                                )}
                            >
                                <LynxIcon
                                    name={rsbAdjustmentsTableExpanded ? "angleDoubleSmallUp" : "angleDoubleSmallDown"}
                                />
                            </LynxButton>
                            <div className={classes.flex}>
                                <BatchDistributionDiagramRsbAdjustmentsTable expanded={rsbAdjustmentsTableExpanded} />
                            </div>
                        </div>
                    )}
                </Controls>
                <Controls
                    showInteractive={false}
                    showFitView={true}
                    position="bottom-right"
                    className={classes.bottomDiagramControls}
                />
            </ReactFlow>

            {showLoading && nodes.length > 0 && (
                <div className={classes.loadingIndicatorContainer}>
                    <CenteredLoadingIndicator />
                </div>
            )}
        </div>
    );
});
