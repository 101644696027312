import {
    HistoricalDeliveryModel,
    GetBatchDistributionDiagramRequest,
    GetBatchDistributionDiagramResponse,
} from "api/models/events/eventsApi";
import { services } from "api/serviceConfig";
import { commonConstants } from "lynxConstants";
import { makeAutoObservable } from "mobx";
import CommonStore from "./CommonStore";
import { HistoricalEventModel } from "models/thorEvents/eventModels";
import { formatTemperatureRange } from "helpers/formatTemperatureRange";
import { eventIsClosed } from "helpers/eventIsClosed";

interface ProgressFlags {
    loadingBatchDistributionDiagram: boolean;
}

interface GroupedDeliveriesDetails {
    originPhysicalId?: string;
    originName?: string;
    destinationPhysicalId?: string;
    destinationName?: string;
    deliveries: HistoricalDeliveryModel[];
}

interface RangeRsbAdjustment {
    rsbAdjustment: number;
    range: string;
}

interface EventRsbAdjustments {
    eventId: string;
    eventDisplayId: number;
    adjustments: RangeRsbAdjustment[];
}

export default class BatchDistributionDiagramStore {
    constructor(commonStore: CommonStore) {
        makeAutoObservable(this);
        this.commonStore = commonStore;
    }

    commonStore: CommonStore;

    progressFlags: ProgressFlags = {
        loadingBatchDistributionDiagram: false,
    };

    areRelevantObjectsHighlighted = true;

    groupedDeliveriesDetails: GroupedDeliveriesDetails | null = null;

    get blankDiagramData(): GetBatchDistributionDiagramResponse {
        return {
            eventId: "",
            eventDisplayId: 0,
            eventTimezone: commonConstants.UTCTimezone,
            productFullName: "",
            batchNumber: "",
            stabilityFormId: null,
            historyShownFrom: "",
            historyShownTo: "",
            assessmentClosedAt: null,
            locations: [],
            deliveries: [],
            flowName: "",
            impacts: [],
            manufacturingPlantFound: null,
            allDeliveriesHaveOriginAndDestination: null,
            batchTrackingEnabledOnBatchLevel: null,
        };
    }

    diagramData = this.blankDiagramData;

    get relevantEvents(): HistoricalEventModel[] {
        return this.allEvents.filter((x) => x.isRelevant);
    }

    get allEvents(): HistoricalEventModel[] {
        const transportationEvents = this.diagramData.deliveries.flatMap((x) => x.events);
        const siteEvents = this.diagramData.locations.flatMap((x) => x.events);

        return [...transportationEvents, ...siteEvents];
    }

    get rsbAdjustments(): EventRsbAdjustments[] {
        let result: EventRsbAdjustments[] = this.allEvents
            .filter((x) => eventIsClosed(x) || x.isCurrent)
            .map((x) => ({
                eventId: x.eventId,
                eventDisplayId: x.eventDisplayId,
                adjustments: x.impacts
                    .filter((y) => y.rsbAdjustment !== null)
                    .sort((a, b) => a.position - b.position)
                    .map((y) => ({
                        rsbAdjustment: y.rsbAdjustment!,
                        range: formatTemperatureRange(
                            y.rangeDisplayLowerLimit,
                            y.rangeDisplayUpperLimit,
                            y.rangeDisplayLowerLimitOperator,
                            y.rangeDisplayUpperLimitOperator
                        ),
                    })),
            }));

        result = result.filter((x) => x.adjustments.length > 0).sort((a, b) => a.eventDisplayId - b.eventDisplayId);

        return result;
    }

    setAreRelevantObjectsHighlighted = (value: boolean) => {
        this.areRelevantObjectsHighlighted = value;
    };

    setProgressFlags = (value: Partial<ProgressFlags>) => {
        this.progressFlags = { ...this.progressFlags, ...value };
    };

    setBatchDistributionDiagram = (value: GetBatchDistributionDiagramResponse) => {
        this.diagramData = value;
    };

    resetBatchDistributionDiagram = () => {
        this.diagramData = this.blankDiagramData;
    };

    openGroupedDeliveriesDetails = (
        originId: string | null,
        destinationId: string | null,
        deliveryNumbers: string[]
    ) => {
        const origin = this.diagramData.locations.find((x) => x.physicalId === originId);
        const destination = this.diagramData.locations.find((x) => x.physicalId === destinationId);

        const deliveries = this.diagramData.deliveries.filter((x) => deliveryNumbers.includes(x.deliveryNumber));
        this.groupedDeliveriesDetails = {
            originPhysicalId: origin?.physicalId,
            originName: origin?.name,
            destinationPhysicalId: destination?.physicalId,
            destinationName: destination?.name,
            deliveries,
        };
    };

    clearGroupedDeliveriesDetails = () => {
        this.groupedDeliveriesDetails = null;
    };

    getBatchDistributionDiagram = async (request: GetBatchDistributionDiagramRequest) => {
        this.setProgressFlags({ loadingBatchDistributionDiagram: true });

        try {
            const response = await services.Events.getBatchDistributionDiagram(request);

            if (response.status === 200) {
                response.data.impacts.sort((a, b) => a.position - b.position);
                this.setBatchDistributionDiagram(response.data);
                return this.diagramData;
            } else {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return null;
            }
        } finally {
            this.setProgressFlags({ loadingBatchDistributionDiagram: false });
        }
    };
}
