import { Collapse, Divider, Grid } from "@material-ui/core";
import { Chart, ChartDataset } from "chart.js";
import { CropTemperatureRange } from "chartjs-plugins/crop-temperature-range/crop-temperature-range-plugin";
import { ConditionPosition } from "chartjs-plugins/crop-temperature-range/interfaces/pluginDeclarations";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { LynxDateTimePicker } from "components/LynxComponents/LynxDateTimePicker/LynxDateTimePicker";
import { LynxInfoBadge } from "components/LynxComponents/LynxInfoBadge";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import LoadingIndicator from "components/ReusableComponents/LoadingIndicator/LoadingIndicator";
import { PromptTooltip } from "components/ReusableComponents/PromptTooltip/PromptTooltip";
import dayjs from "dayjs";
import { getPermissionKey } from "helpers/permissionHelpers";
import { convertDateToSelectedTimezone, dateInLocalTimezone } from "helpers/timezoneHelper";
import { LynxIcon } from "icons/LynxIcon";
import { commonConstants, promptTooltipMessages } from "lynxConstants";
import { observer } from "mobx-react";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import { useEffect, useReducer, useRef, useState } from "react";
import { useStore } from "store/StoreConfigs";
import DeviceDetails from "../DeviceDetails/DeviceDetails";
import { DeviceDetailsInfo } from "../DeviceDetails/DeviceDetailsInfo";
import { TemperatureChartAuthModal } from "./TemperatureChartAuthModal";
import { TemperatureChartProps } from "./TemperatureChartProps";
import { thorTemperatureChartStyles } from "./ThorTemperatureChartStyles";
import { createOptions } from "./config";
import { eventIsClosed } from "helpers/eventIsClosed";

export default observer(function ThorTemperatureChart({
    devices,
    isLoading,
    temperatureUpperLimit,
    temperatureLowerLimit,
    excursionSegments,
    isConditioned,
    minTemperature,
    maxTemperature,
}: TemperatureChartProps) {
    const classes = thorTemperatureChartStyles();
    const { thorEventViewStore, permissionsStore, identityStore } = useStore();
    const details = thorEventViewStore.eventDetails;

    const permissionKey = getPermissionKey(actions.customer.tor.events.conditionTrip, details.id);
    const permissionStatus = permissionsStore.getPermissionStatus(permissionKey);
    const divRef = useRef<HTMLCanvasElement | null>(null);
    const isEditGraphButtonDisabled = permissionStatus !== MarvelPermissionStatus.Allow || eventIsClosed(details);

    const [chart, setChart] = useState<Chart>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deviceDetails, setDeviceDetails] = useState<DeviceDetailsInfo>();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isAnyPointSelected, setIsAnyPointSelected] = useState(false);
    const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
    const [leftConditioning, setLeftConditioning] = useState<number>(dayjs().valueOf());
    const [rightConditioning, setRightConditioning] = useState<number>(dayjs().valueOf());
    const [conditioningModeEnabled, setConditioningModeEnabled] = useState<boolean>(false);

    // force rerender method. Using to handle chart.js chart state changes
    const [, forceUpdate] = useReducer((x: any) => x + 1, 0);

    if (!devices) {
        return <div>Data not available</div>;
    }

    const maximumAllowedNumberOfPointsWithActiveAnimations = 20000;

    let maxNumberOfPoints = 0;
    let intervalOfLongestData = 0;
    let totalNumberOfPoints = 0;
    let minDate = Infinity;
    let maxDate = -Infinity;

    devices.forEach((device) => {
        if (device.data.length !== 0) {
            const dataLength = device.data.length;
            totalNumberOfPoints += device.data.length;

            if (dataLength > maxNumberOfPoints) {
                maxNumberOfPoints = dataLength;
                if (dataLength >= 2) {
                    const firstDataPoint = device.data[0];
                    const secondDataPoint = device.data[1];

                    intervalOfLongestData = secondDataPoint.x - firstDataPoint.x;
                }
            }

            const firstPointDate = device.data[0].x;
            const lastPointDate = device.data[device.data.length - 1].x;

            if (firstPointDate < minDate) {
                minDate = firstPointDate;
            }

            if (lastPointDate > maxDate) {
                maxDate = lastPointDate;
            }
        }
    });

    const isChartAnimationActive =
        totalNumberOfPoints > maximumAllowedNumberOfPointsWithActiveAnimations ? false : undefined;

    const datesFromDevices = devices.flatMap((device) => device.data).map((dataPoint) => dataPoint.x);

    const datasets: ChartDataset<"line">[] = devices.map((item) => ({
        label: item.label,
        data: item.data,
        borderColor: item.backgroundColor,
        // this value allow to intersect points in each dataset.
        pointHitRadius: 15,
        backgroundColor: item.backgroundColor,
        borderWidth: item.borderWidth,
    }));

    const onConditionChangedBySlider = (position: ConditionPosition, value: number): void => {
        if (position === ConditionPosition.left) {
            setLeftConditioning(value);
        }
        if (position === ConditionPosition.right) {
            setRightConditioning(value);
        }
    };

    const switchConditioningMode = (chart: Chart | undefined): void => {
        const modeStatus = !conditioningModeEnabled;
        if (chart?.enableConditioning && modeStatus) {
            chart.enableConditioning();
        }
        if (chart?.disableConditioning && !modeStatus) {
            chart.disableConditioning();
        }
        setConditioningModeEnabled(modeStatus);
    };

    const applyConditioning = (chart: Chart | undefined): void => {
        if (chart?.applyConditioningChanges) {
            setIsModalOpen(true);
        }
    };

    const leftSliderConditioning =
        details.conditioningLeft === null ? null : dateInLocalTimezone(details.conditioningLeft).valueOf();

    const rightSliderConditioning =
        details.conditioningRight === null ? null : dateInLocalTimezone(details.conditioningRight).valueOf();

    useEffect(() => {
        const isAnyDatapointsInDateRange = datesFromDevices.some(
            (date) => date >= leftConditioning && date <= rightConditioning
        );

        setIsAnyPointSelected(isAnyDatapointsInDateRange);
    }, [rightConditioning, leftConditioning]);

    useEffect(() => {
        if (divRef.current === null) {
            return;
        }

        const options = createOptions(
            minDate,
            maxDate,
            minTemperature,
            maxTemperature,
            leftSliderConditioning,
            rightSliderConditioning,
            temperatureLowerLimit,
            temperatureUpperLimit,
            excursionSegments,
            maxNumberOfPoints,
            intervalOfLongestData,
            onConditionChangedBySlider,
            details.timezone,
            isChartAnimationActive
        );

        const cropTemperatureRange = new CropTemperatureRange();
        const chartInstance = new Chart(divRef.current, {
            type: "line",
            data: {
                datasets,
            },
            options: options,
            plugins: [cropTemperatureRange],
        });

        let hoverTimeout: any;

        const canvas = divRef.current;

        const handleMouseEnter = () => {
            hoverTimeout = setTimeout(() => {
                chartInstance.options.plugins!.zoom!.zoom!.wheel!.enabled = true;
                chartInstance.update();
            }, 1500);
        };

        const handleMouseLeave = () => {
            clearTimeout(hoverTimeout);

            chartInstance.options.plugins!.zoom!.zoom!.wheel!.enabled = false;
            chartInstance.update();
        };

        canvas.addEventListener("mouseenter", handleMouseEnter);
        canvas.addEventListener("mouseleave", handleMouseLeave);

        chartInstance.update();
        setChart(chartInstance);
        setIsLoadingState(isLoading);

        return () => {
            canvas.removeEventListener("mouseenter", handleMouseEnter);
            canvas.removeEventListener("mouseleave", handleMouseLeave);
            chartInstance.destroy();
        };
    }, [devices]);

    useEffect(() => {
        if (conditioningModeEnabled) {
            switchConditioningMode(chart);
        }
    }, [details.reviewerUserId, details.qaUserId]);

    useEffect(() => {
        forceUpdate();
    }, [details.timezone]);

    const getInfoBadgeText = () => {
        const parts = [`Edited by ${details.conditioningAppliedByFirstName} ${details.conditioningAppliedByLastName}`];
        details.conditioningNote?.trim() && parts.push(`: ${details.conditioningNote}`);
        const conditioningDate = convertDateToSelectedTimezone(details.conditioningAppliedDt, details.timezone);
        parts.push(` (${conditioningDate.format(commonConstants.fullDateTimeFormat)})`);
        return parts.join("");
    };

    const getTooltipMessage = () => {
        const isCurrentUserIsReviever = thorEventViewStore.eventDetails.reviewerUserId === identityStore.currentUser.id;
        const isCurrentUserIsQA = thorEventViewStore.eventDetails.qaUserId === identityStore.currentUser.id;

        switch (true) {
            case eventIsClosed(thorEventViewStore.eventDetails):
                return promptTooltipMessages.event.decisionCompleted;
            case !(isCurrentUserIsReviever || isCurrentUserIsQA):
                return promptTooltipMessages.event.assignToYourself;
            case permissionStatus !== MarvelPermissionStatus.Allow:
                return promptTooltipMessages.accessDenied;
            default:
                return promptTooltipMessages.empty;
        }
    };

    const convertedLeftConditioning = convertDateToSelectedTimezone(leftConditioning, details.timezone);
    const convertedRightConditioning = convertDateToSelectedTimezone(rightConditioning, details.timezone);

    return (
        <>
            <TemperatureChartAuthModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                conditioningStartDate={dateInLocalTimezone(leftConditioning).toDate()}
                conditioningEndDate={dateInLocalTimezone(rightConditioning).toDate()}
            />
            <LynxTypography variant={"h2"}>Temperature Graph</LynxTypography>
            <Grid container justifyContent="center">
                <Grid item xs={11}>
                    <div className={classes.container}>
                        {isLoadingState ? (
                            <LoadingIndicator className={classes.loadingIndicator} />
                        ) : (
                            <>
                                <Grid
                                    container
                                    alignItems="flex-end"
                                    justifyContent="space-between"
                                    className={classes.gridContainer}
                                >
                                    <Grid item>
                                        <Grid container>
                                            <Grid
                                                item
                                                className={clsx(classes.marginRight, {
                                                    [classes.hide]: !conditioningModeEnabled,
                                                })}
                                            >
                                                <LynxDateTimePicker
                                                    label={"First Point"}
                                                    disabled={true}
                                                    value={convertedLeftConditioning}
                                                    format={commonConstants.fullDateTimeFormat}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                className={clsx({
                                                    [classes.hide]: !conditioningModeEnabled,
                                                })}
                                            >
                                                <LynxDateTimePicker
                                                    label={"Last Point"}
                                                    disabled={true}
                                                    value={convertedRightConditioning}
                                                    format={commonConstants.fullDateTimeFormat}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        {conditioningModeEnabled ? (
                                            <>
                                                <LynxButton
                                                    size="medium"
                                                    className={classes.marginRight}
                                                    variant="icon"
                                                    onClick={() => chart?.undo()}
                                                    disabled={chart?.isUndoDisabled()}
                                                >
                                                    {/* UNDO */}
                                                    <LynxIcon name="revert" />
                                                </LynxButton>

                                                <LynxButton
                                                    size="medium"
                                                    className={classes.marginRight}
                                                    variant="icon"
                                                    disabled={chart?.isRedoDisabled()}
                                                    onClick={() => chart?.redo()}
                                                >
                                                    {/* REDO */}
                                                    <LynxIcon name="redo" />
                                                </LynxButton>
                                                <PromptTooltip
                                                    title={
                                                        !isAnyPointSelected ? "There are no datapoints selected" : ""
                                                    }
                                                    placement={"top"}
                                                >
                                                    <LynxButton
                                                        className={classes.marginRight}
                                                        variant="primary"
                                                        disabled={
                                                            chart?.isApplyChangesDisabled() || !isAnyPointSelected
                                                        }
                                                        onClick={() => applyConditioning(chart)}
                                                    >
                                                        Apply
                                                    </LynxButton>
                                                </PromptTooltip>
                                                <LynxButton
                                                    variant="secondary"
                                                    onClick={() => switchConditioningMode(chart)}
                                                    className={classes.marginRight}
                                                >
                                                    Discard
                                                </LynxButton>
                                            </>
                                        ) : (
                                            <>
                                                <LynxButton
                                                    variant="tertiary"
                                                    onClick={() => chart?.resetZoom()}
                                                    className={classes.marginRight}
                                                >
                                                    Reset zoom
                                                </LynxButton>
                                                <PromptTooltip title={getTooltipMessage()} placement="top">
                                                    <LynxButton
                                                        className={classes.marginRight}
                                                        variant="secondary"
                                                        disabled={isEditGraphButtonDisabled}
                                                        loading={permissionStatus === MarvelPermissionStatus.Loading}
                                                        onClick={() => switchConditioningMode(chart)}
                                                        leftIcon={<LynxIcon name="edit" />}
                                                    >
                                                        Annotate
                                                    </LynxButton>
                                                </PromptTooltip>
                                            </>
                                        )}
                                    </Grid>
                                </Grid>

                                <canvas ref={divRef} />
                            </>
                        )}
                    </div>
                </Grid>

                <Grid item xs={11} className={classes.infoContainer}>
                    {details.conditioningAppliedDt && <LynxInfoBadge text={getInfoBadgeText()} fitContent />}
                </Grid>
            </Grid>

            <Grid container spacing={4} direction="row" justifyContent="flex-start">
                {devices.map((x, index) => (
                    <Grid
                        item
                        direction="row"
                        key={x.label}
                        className={clsx(classes.devicesInfoRow, {
                            [classes.selectedMonitor]: thorEventViewStore.selectedDevise === x.label,
                        })}
                    >
                        <Divider
                            orientation="vertical"
                            className={clsx(classes.monitorLeftBorder, {
                                [classes.selectedMonitorLeftBorder]: thorEventViewStore.selectedDevise === x.label,
                            })}
                        />
                        <Grid container alignItems="center">
                            <div className={classes.monitorDetailsContainer}>
                                <div
                                    style={{
                                        background: `${x.borderColor}`,
                                        borderColor: `${x.borderColor}`,
                                        height: "1rem",
                                        width: "1rem",
                                        borderRadius: "1rem",
                                    }}
                                ></div>

                                {x.hasAlarm && (
                                    <LynxIcon name="triangleWarning" className={classes.triangleWarningIcon} />
                                )}

                                <LynxTypography variant="body-medium">{x.label}</LynxTypography>

                                {chart ? (
                                    <>
                                        {chart!.data.datasets[index].hidden ? (
                                            <LynxIcon
                                                name="eyeCrossed"
                                                className={classes.eyeIcon}
                                                onClick={() => {
                                                    chart!.data.datasets[index].hidden = false;
                                                    chart?.update();
                                                    // using to switch icons after changes.
                                                    forceUpdate();
                                                }}
                                            />
                                        ) : (
                                            <LynxIcon
                                                name="eye"
                                                className={classes.eyeIcon}
                                                onClick={() => {
                                                    chart!.data.datasets[index].hidden = true;
                                                    chart?.update();
                                                    setIsCollapsed(false);
                                                    thorEventViewStore.setSelectedDevise("");
                                                    // using to switch icons after changes.
                                                    forceUpdate();
                                                }}
                                            />
                                        )}
                                    </>
                                ) : null}
                            </div>
                        </Grid>
                        <Grid item>
                            <LynxButton
                                variant="tertiary"
                                disabled={chart?.data.datasets[index].hidden}
                                className={classes.button}
                                onClick={
                                    isCollapsed && thorEventViewStore.selectedDevise === x.label
                                        ? () => {
                                              setIsCollapsed(false);
                                              thorEventViewStore.setSelectedDevise("");
                                          }
                                        : () => {
                                              setDeviceDetails({ ...x, isConditioned });
                                              setIsCollapsed(true);
                                              thorEventViewStore.setSelectedDevise(x.label);
                                          }
                                }
                                rightIcon={
                                    <LynxIcon
                                        name="angleSmallDown"
                                        className={clsx({
                                            [classes.rotate]:
                                                isCollapsed && thorEventViewStore.selectedDevise === x.label,
                                        })}
                                    />
                                }
                            >
                                {isCollapsed && thorEventViewStore.selectedDevise === x.label ? "Hide" : "Show"} Monitor
                                Details
                            </LynxButton>
                        </Grid>
                    </Grid>
                ))}
            </Grid>

            <Collapse in={isCollapsed}>{deviceDetails && <DeviceDetails {...deviceDetails} />}</Collapse>
        </>
    );
});
