import { Drawer, Grid } from "@material-ui/core";
import {
    GetEventAttachmentsRequest,
    GetEventDetailsRequest,
    GetEventDeviceDataRequest,
} from "api/models/events/eventsApi";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { FullScreenLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/FullScreenLoadingIndicator";
import { LynxBackButton } from "components/ReusableComponents/LynxBackButton/LynxBackButton";
import LynxComments from "components/ReusableComponents/LynxComments/LynxComments";
import { PromptTooltip } from "components/ReusableComponents/PromptTooltip/PromptTooltip";
import { envConstants } from "env-constants";
import { displayPopupNotification } from "helpers/displayPopupNotification";
import { createPermissionRequest, getPermissionKey } from "helpers/permissionHelpers";
import { dateInUTC } from "helpers/timezoneHelper";
import { isGuidValid } from "helpers/typeValidationHelpers";
import { LynxIcon } from "icons/LynxIcon";
import { observer } from "mobx-react";
import { NotificationType } from "models/shared/NotificationType";
import { AssessmentStatus, EventType, ExcursionSource } from "models/thorEvents/eventModels";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus, PermissionRequest, ResourceType } from "models/userManagement/userManagementModels";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { routes } from "routes";
import { useStore } from "store/StoreConfigs";
import { SectionCard } from "../../ReusableComponents/Cards/SectionCard";
import { Assessment } from "../Assessment/Assessment";
import { AssignEventMenuModal } from "../AssignEventMenuModal/AssignEventMenuModal";
import EventAttachments from "../EventAttachments/EventAttachments";
import { EventDescription } from "../EventDescription/EventDescription";
import { EventInfo } from "../EventInfo/EventInfo";
import EventLevelInformation from "../EventLevelInformation/EventLevelInformation";
import EventTransportationInfo from "../EventLocationInfo/EventTransportationInfo";
import EventSiteLocationInfo from "../EventLocationInfo/EventSiteLocationInfo";
import ThorTemperatureChart from "../ThorTemperatureChart/ThorTemperatureChart";
import { UploadArea } from "../UploadArea/UploadArea";
import { eventDetailsStyles } from "./EventDetailsStyles";
import { PreviousEventsWarning } from "./PreviousEventsWarning";
import { GeneralErrorComponentWrapper } from "components/ErrorComponents/GeneralErrorPage";
import { eventIsClosed } from "helpers/eventIsClosed";

const getEventSpecificPermissionsRequests = (userId: string, eventId: string): PermissionRequest[] => {
    const result: PermissionRequest[] = [
        createPermissionRequest(userId, actions.customer.tor.events.editDetails, ResourceType.Event, eventId, true),
        createPermissionRequest(userId, actions.customer.tor.events.editInformation, ResourceType.Event, eventId, true),
        createPermissionRequest(userId, actions.customer.tor.events.approve, ResourceType.Event, eventId, true),
        createPermissionRequest(userId, actions.customer.tor.events.review, ResourceType.Event, eventId, true),
        createPermissionRequest(userId, actions.customer.tor.events.conditionTrip, ResourceType.Event, eventId, true),
        createPermissionRequest(
            userId,
            `${actions.customer.tor.events.editManualEvent}::event`,
            ResourceType.Event,
            eventId,
            true
        ),
    ];

    return result;
};

export const EventDetails = observer(() => {
    const classes = eventDetailsStyles();
    const { eventId } = useParams();
    const navigate = useNavigate();

    const { thorEventViewStore, identityStore, permissionsStore, customerDataStore, commonStore } = useStore();
    const event = thorEventViewStore.eventDetails;

    const permissionRequest = getEventSpecificPermissionsRequests(identityStore.currentUser.id, eventId || "");

    const viewDetailsPermissionKey = getPermissionKey(
        actions.customer.tor.events.viewDetails,
        identityStore.currentCustomer.id
    );

    const viewDetailsPermissionStatus = permissionsStore.getPermissionStatus(viewDetailsPermissionKey);

    const waitingForPermissionCheck =
        viewDetailsPermissionStatus === undefined || viewDetailsPermissionStatus === MarvelPermissionStatus.Loading;
    const accessDenied =
        eventId === undefined ||
        identityStore.isSystemSpace ||
        viewDetailsPermissionStatus === MarvelPermissionStatus.Deny;

    const allAssessmentsArePendingReview = event.assessments.every((x) => x.status === AssessmentStatus.PendingReview);

    const isEventIdValid = isGuidValid(eventId);

    const toastDevLongText = "Event information was populated while editing automatic event. You can proceed.";
    const toastDevShortText = "Event information was not populated.";

    useEffect(() => {
        if (accessDenied) {
            navigate(identityStore.startPageAvailable);
        }
    }, [accessDenied]);

    useEffect(() => {
        if (!isEventIdValid) {
            return navigate(routes.pageNotFound);
        }
    }, [eventId]);

    useEffect(() => {
        if (accessDenied || !isEventIdValid) {
            return;
        }

        permissionsStore.bulkEvaluate(permissionRequest);
    }, [event.qaUserId, event.reviewerUserId]);

    useEffect(() => {
        if (accessDenied || waitingForPermissionCheck) {
            return;
        }

        const request: GetEventDetailsRequest = {
            customerId: identityStore.currentCustomer.id,
            eventId: eventId,
        };
        const deviceDataRequest: GetEventDeviceDataRequest = request;

        thorEventViewStore.loadEventDetails(request);

        thorEventViewStore.loadDeviceData(deviceDataRequest);

        return () => commonStore.setShowGeneralErrorPageToFalse();
    }, [viewDetailsPermissionStatus, thorEventViewStore.isFlowChanged]);

    useEffect(() => {
        if (accessDenied || waitingForPermissionCheck) {
            return;
        }

        const attachmentsRequest: GetEventAttachmentsRequest = {
            customerId: identityStore.currentCustomer.id,
            eventId: eventId,
            pageNumber: 1,
            pageSize: 15,
        };

        thorEventViewStore.loadEventAttachments(attachmentsRequest, true);
    }, [viewDetailsPermissionStatus, thorEventViewStore.attachmentsFirstSetLoadingCount]);

    useEffect(() => {
        if (identityStore.isSystemSpace) {
            return;
        }

        const request = { customerId: identityStore.currentCustomer.id };

        if (customerDataStore.eventPriorities === null && !customerDataStore.progressFlags.loadingPriorities) {
            customerDataStore.loadEventPriorities(request);
        }

        if (customerDataStore.eventCodes.length === 0 && !customerDataStore.progressFlags.loadingEventCodes) {
            customerDataStore.loadEventCodes(request);
        }
    }, [viewDetailsPermissionStatus]);

    useEffect(() => {
        if (event.openedPreviousEvents.length > 0 && eventId === event.id) {
            displayPopupNotification(
                NotificationType.WARNING,
                <PreviousEventsWarning events={event.openedPreviousEvents} />,
                "Previous events not evaluated",
                event.id + "-previous-events-warning"
            );
        }
    }, [event]);

    return (
        <GeneralErrorComponentWrapper>
            <UploadArea onFileUploadNotification={displayPopupNotification} isEventCreatingOrEditing={false} />
            <Drawer anchor="right" open={thorEventViewStore.isAssignMenuOpen}>
                <AssignEventMenuModal
                    eventId={event.id}
                    reviewerUserId={event.reviewerUserId}
                    qaUserId={event.qaUserId}
                    eventStatus={event.status}
                />
            </Drawer>

            <main className={classes.root}>
                <Helmet>
                    <title>{`Event #${event.displayId}`}</title>
                </Helmet>
                {viewDetailsPermissionStatus !== MarvelPermissionStatus.Allow ||
                thorEventViewStore.progressFlags.loadingEventDetails ||
                thorEventViewStore.progressFlags.loadingDeviceData ? (
                    <div>
                        <FullScreenLoadingIndicator />
                    </div>
                ) : (
                    <>
                        <div className={classes.buttonsAboveHeaderContainer}>
                            <LynxBackButton path={routes.events} title={"Events List"} />
                            {event.id && (
                                <div>
                                    <LynxButton
                                        variant="tertiary"
                                        onClick={() => navigate(`/events/${event.id}/audit`)}
                                        leftIcon={<LynxIcon name="history" />}
                                    >
                                        Audit Trail
                                    </LynxButton>
                                    <LynxButton
                                        variant="tertiary"
                                        onClick={() =>
                                            thorEventViewStore.generatePdf(identityStore.currentCustomer.id, event.id)
                                        }
                                        leftIcon={<LynxIcon name="print" />}
                                        loading={thorEventViewStore.progressFlags.exportingPdf}
                                        disabled={thorEventViewStore.progressFlags.exportingPdf}
                                    >
                                        Print
                                    </LynxButton>
                                </div>
                            )}
                        </div>

                        <>
                            <SectionCard>
                                <EventInfo allAssessmentsArePendingReview={allAssessmentsArePendingReview} />

                                <Grid container className={clsx(classes.eventInfoPadding)}>
                                    <Grid item container xs={7} direction="column">
                                        <Grid item>
                                            <EventDescription />
                                        </Grid>
                                        <Grid item>
                                            {event.type === EventType.Site ? (
                                                <EventSiteLocationInfo />
                                            ) : (
                                                <EventTransportationInfo />
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1} />
                                    <Grid item xs={4}>
                                        <PromptTooltip placement="top" title="title block">
                                            <EventLevelInformation />
                                        </PromptTooltip>
                                    </Grid>
                                </Grid>
                            </SectionCard>

                            {event.excursionSource === ExcursionSource.TemperatureRecordingDevice && (
                                <SectionCard>
                                    <ThorTemperatureChart
                                        minTemperature={event.minTemperature}
                                        maxTemperature={event.maxTemperature}
                                        devices={thorEventViewStore.devicesData.map((deviceData) => ({
                                            ...deviceData,
                                            label: deviceData.serialNumber,
                                            data: deviceData.dataPoints.map((dataPoint) => ({
                                                x: dateInUTC(dataPoint.timestamp).valueOf(),
                                                y: dataPoint.value,
                                            })),
                                        }))}
                                        isLoading={thorEventViewStore.progressFlags.loadingDeviceData}
                                        temperatureUpperLimit={event.temperatureActualUpperLimit}
                                        temperatureLowerLimit={event.temperatureActualLowerLimit}
                                        excursionSegments={event.excursionSegments}
                                        isConditioned={!!event.conditioningAppliedDt}
                                    />
                                </SectionCard>
                            )}

                            <SectionCard id="event_assessment_section">
                                <Assessment />
                            </SectionCard>

                            <SectionCard id="event_comments_and_attachments_section">
                                <LynxTypography variant="h2" className={classes.subTitleMargin}>
                                    Comments & Attachments
                                </LynxTypography>
                                <Grid container justifyContent="space-between">
                                    <Grid item container direction="column" xs={6} className={classes.commentsGrid}>
                                        <LynxComments
                                            rootEntityId={eventId}
                                            timezone={thorEventViewStore.eventDetails.timezone}
                                            isDisabled={eventIsClosed(thorEventViewStore.eventDetails)}
                                        />
                                    </Grid>
                                    <Grid item container direction="column" xs={4}>
                                        <EventAttachments />
                                    </Grid>
                                </Grid>
                            </SectionCard>

                            {envConstants.REACT_APP_RESET_EVENT_FEATURE_FLAG && (
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <LynxButton
                                            variant="secondary"
                                            onClick={() => {
                                                thorEventViewStore.resetEvent({
                                                    eventId: event.id,
                                                    customerId: identityStore.currentCustomer.id,
                                                });
                                            }}
                                        >
                                            Reset Event (DEV FEATURE)
                                        </LynxButton>
                                    </Grid>
                                    <Grid item>
                                        <LynxButton
                                            variant="secondary"
                                            onClick={() =>
                                                displayPopupNotification(NotificationType.SUCCESS, toastDevLongText)
                                            }
                                        >
                                            Show Success Notification (DEV FEATURE)
                                        </LynxButton>
                                    </Grid>
                                    <Grid item>
                                        <LynxButton
                                            variant="secondary"
                                            onClick={() =>
                                                displayPopupNotification(NotificationType.ERROR, toastDevShortText)
                                            }
                                        >
                                            Show Error Notification (DEV FEATURE)
                                        </LynxButton>
                                    </Grid>
                                    <Grid item>
                                        <LynxButton
                                            variant="secondary"
                                            onClick={() =>
                                                displayPopupNotification(NotificationType.INFORMATION, toastDevLongText)
                                            }
                                        >
                                            Show Information Notification (DEV FEATURE)
                                        </LynxButton>
                                    </Grid>
                                    <Grid item>
                                        <LynxButton
                                            variant="secondary"
                                            onClick={() =>
                                                displayPopupNotification(NotificationType.WARNING, toastDevShortText)
                                            }
                                        >
                                            Show Warning Notification (DEV FEATURE)
                                        </LynxButton>
                                    </Grid>
                                </Grid>
                            )}
                        </>
                    </>
                )}
            </main>
        </GeneralErrorComponentWrapper>
    );
});
